<template>
  <div>
    <amplify-authenticator username-alias="email">
        <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        :formFields.prop="signUpFields"
        >
        </amplify-sign-up>
    </amplify-authenticator>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth } from 'aws-amplify';
export default {
  name: 'Login',
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    })
  },
  watch: {
    authState(val) {
      if (val === "signedin") {
        this.$router.push("/")
      }
    }
  },
  components: {
  },
  data() {
    return {
      user: undefined,
      hide: true,
      authState: undefined,
      unsubscribeAuth: undefined,
      userData: undefined,
      userDetails: {
            username: "",
            password: "",
            attributes: {
                email: ""
            }
      },
      signUpFields: [
            { 
                type: "email"
            },
            { 
              label: "First Name *",
              type: "given_name"
            },
            { 
              label: "Last Name *",
              type: "family_name",
            },
            { 
              type: "password"
            },
            { 
              type: "phone_number",
              required: false,
              label: "Phone"
            },
            { 
              type: "custom:code",
              required: true,
              label: "Dealer Code"
            }  
        ],
    }
  },
  methods: {
    async signUp(event) {
        event.preventDefault()
        try {
            const { user } = await Auth.signUp(this.userDetails);
            console.log(user);
        } catch (error) {
            console.log('error signing up:', error);
        }
    }
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  }
}
</script>


